exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atelier-js": () => import("./../../../src/pages/atelier.js" /* webpackChunkName: "component---src-pages-atelier-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-efoil-js": () => import("./../../../src/pages/locations/efoil.js" /* webpackChunkName: "component---src-pages-locations-efoil-js" */),
  "component---src-pages-locations-esurf-js": () => import("./../../../src/pages/locations/esurf.js" /* webpackChunkName: "component---src-pages-locations-esurf-js" */),
  "component---src-pages-locations-index-js": () => import("./../../../src/pages/locations/index.js" /* webpackChunkName: "component---src-pages-locations-index-js" */),
  "component---src-pages-locations-seabob-js": () => import("./../../../src/pages/locations/seabob.js" /* webpackChunkName: "component---src-pages-locations-seabob-js" */),
  "component---src-pages-vente-js": () => import("./../../../src/pages/vente.js" /* webpackChunkName: "component---src-pages-vente-js" */)
}

